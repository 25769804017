import React from "react";
import { styled } from "../../stitches.config";

const Separator = () => (
  <Root>
    <Line />
  </Root>
);

const Root = styled("div", {
  padding: "40px 0",
});

const Line = styled("div", {
  width: "100%",
  height: "1px",
  backgroundColor: "#E7E7E7",
});

export default Separator;
